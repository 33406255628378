<template>
  <div
    v-if="articles.length"
    class="useful-articles"
  >
    <div class="container">
      <div class="useful-flex">
        <h3 class="title">{{ $t("usefulArticles") }}</h3>
        <locale-router-link
          to="useful-articles"
          class="page__top-link header-block__link"
        >
          {{ $t("readMore") }}
        </locale-router-link>
      </div>
      <div class="useful-articles__inner">
        <div
          v-for="(item, index) in articles"
          :key="index"
          class="useful-articles__item"
          :class="{ big: checkItemSize(index) }"
        >
          <locale-router-link
            :to="`/useful-articles/${item.id}`"
            class="useful-articles__content"
          >
            <p class="useful-articles__date">
              {{ getDateFormat(item.attributes.publishedAt) }}
            </p>
            <h6 class="useful-articles__title">{{ item.attributes.header }}</h6>
          </locale-router-link>
          <locale-router-link :to="`/useful-articles/${item.id}`">
            <img
              v-lazy="getImage(item)"
              class="useful-articles__image"
          /></locale-router-link>
        </div>
        <button
          class="useful-articles__button"
          v-if="!pagination.isLastPage"
          @click="incrementItems"
        >
          {{ $t("seeAllBtn") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"
import { getArticles } from "@/api/articles"
import LocaleRouterLink from "../language/LocaleRouterLink.vue"

export default {
  name: "AppUsefulArticles",

  components: { LocaleRouterLink },

  data() {
    return {
      articles: [],
      pagination: {
        page: 1,
        pageSize: 5,
        isLastPage: false,
      },
      showSeeAll: false,
    }
  },

  computed: {},

  created() {
    if (this.$isMobile()) {
      this.pagination.pageSize = 3
    }
    getArticles(this.pagination.page, this.pagination.pageSize, "article").then((res) => {
      this.articles = this.getFilteredArticles(res.data)
      if (this.articles.length < res.meta.pagination.total) {
        this.showSeeAll = true
        this.pagination.isLastPage = false
      } else {
        this.showSeeAll = false
        this.pagination.isLastPage = true
      }
    })
  },

  watch: {
    "pagination.page": {
      handler() {
        getArticles(this.pagination.page, this.pagination.pageSize, "article").then((res) => {
          const newArticles = this.getFilteredArticles(res.data)
          this.articles = [...this.articles, ...newArticles]
          if (this.articles.length < res.meta.pagination.total) {
            this.showSeeAll = true
            this.pagination.isLastPage = false
          } else {
            this.showSeeAll = false
            this.pagination.isLastPage = true
          }
        })
      },
    },
  },

  methods: {
    incrementItems() {
      this.pagination.page++
    },
    checkItemSize(e) {
      const index = e + 1
      return index % 5 === 1 || index % 5 === 2
    },
    getFilteredArticles(articles) {
      const filteredArticles = articles.filter((item) => {
        return item.attributes.type === "article"
      })
      return filteredArticles
    },
    getDateFormat(date) {
      return moment(date).locale(this.$i18n.locale).format("LL")
    },
    getImage(item) {
      const urlImg = item?.attributes?.image?.data?.attributes?.url
      if (urlImg) {
        return this.$helpers.getAbsolutePath(urlImg)
      }
      return require("@/" + "assets/img/icons/placeholder.svg")
    },
    // nextArticlesPage() {
    //   this.pagination.page++
    // },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.isVisible {
  display: none;
}
.title {
  font-size: 38px;
  line-height: 87.69%;
  font-family: "CeraPro Bold";
  color: #2d2f32;
}
@media (max-width: 900px) {
  .title {
    font-size: 34px;
  }
}
@media (max-width: 768px) {
  .title {
    font-size: 20px;
    /* max-width: 140px; */
  }
}
</style>
