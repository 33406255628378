import axios from "@/plugins/axios"
import qs from "qs"

export const getArticles = async (page = 1, pageSize = 500, type, excludeId) => {
  const query = qs.stringify({
    pagination: {
      page,
      pageSize,
    },
    populate: "*",
    cities: {
      populate: "*",
    },
    filters: {
      id: {
        $ne: excludeId,
      },
      type: {
        $contains: type,
      },
    },
  })
  const response = await axios.get(`/articles?${query}`)
  return response?.data
}

export const getArticleById = async (id) => {
  const query = qs.stringify({
    populate: "*",
    cities: {
      populate: "*",
    },
  })
  const response = await axios.get(`/articles/${id}?${query}`)
  return response?.data
}
